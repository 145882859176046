<template>
    <ul class="type-list">
        <li v-for="(item,index) in typeData"
            :key="index"
            class="type-item"
            :class="currentType ==  item? 'current' : ''"
            @click="changeType(item)">
            {{item=="全部"?'全部技师':item}}
        </li>
    </ul>
</template>

<script>
	export default {
		name:"technician-type",
    data(){
      return{
        typeData:[]
      }
    },
    props:{
      typeList:{
        type:Object,
        default:()=>{}
      },
      currentType:{
        type:String,
        default:"total"
       }
    },
    watch:{
      typeList(val){
        this.typeData=new Array();
        for (let i in val) {
          if(i=='全部'){this.typeData.unshift(i)}else{this.typeData.push(i)}
        }
      }
    },
		methods:{
			changeType(item){
				this.$emit('changeType',item)
      }
		}
	}
</script>

<style lang="scss" type="text/scss" scoped>
    @import "~@/assets/style/public.scss";

    .type-list{
        width:100vw;
        overflow-x:scroll;
        overflow-y:hidden;
        height:92px;
        border-bottom:1px solid $border-color;
        background:#FFFFFF;
        color:#666666;
        white-space:nowrap;
    }
    .type-list::-webkit-scrollbar-track-piece {
        background-color: rgba(0, 0, 0, 0);
        border-left: 1px solid rgba(0, 0, 0, 0);
    }
    .type-list::-webkit-scrollbar {
        width: 5px;
        height: 6px;
        -webkit-border-radius: 3px;
        -moz-border-radius: 3px;
        border-radius: 3px;
    }
    .type-list::-webkit-scrollbar-thumb {
        background-color: rgba(0, 0, 0, 0.3);
        background-clip: padding-box;
        -webkit-border-radius: 3px;
        -moz-border-radius: 3px;
        border-radius: 3px;
    }
    .type-list::-webkit-scrollbar-thumb:hover {
        background-color: rgba(0, 0, 0, 0.3);
        -webkit-border-radius: 3px;
        -moz-border-radius: 3px;
        border-radius: 3px;
    }
    .type-item{
        display:inline-block;
        text-align:center;
        padding:0 50px;
        font-size:28px;
        line-height:86px;
        position:relative;

        &.current{
            color:$high-light-color;
        }

        &.current:after{
            content:'';
            display:inline-block;
            width:40px;
            height:4px;
            position:absolute;
            left:50%;
            bottom:0;
            margin:0 0 0 -20px;
            z-index:1;
            background:$high-light-color;
        }
    }

</style>
